import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";

import socialIcon1 from "../images/discord-logo.png";
import socialIcon2 from "../images/twitter-logo.png";
import socialIcon3 from "../images/bl-ic-logo.png";
import socialIcon4 from "../images/instagram-logo.png";

import {useDispatch, useSelector} from "react-redux";
import {fetchMaintenance} from "../redux/Maintenance/maintenanceSlice";

function Maintenance() {
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeywords, setMetaKeywords] = useState('');

    const maintenanceData = useSelector((state) => state.maintenance.maintenanceData);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchMaintenance())
    }, [dispatch]);

    useEffect(() => {
        setMetaTitle(maintenanceData?.c_browser_title);
        setMetaDescription(maintenanceData?.c_meta_description);
        setMetaKeywords(maintenanceData?.c_meta_keywords);
    }, [maintenanceData]);

    return (
        <div>
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
                <meta name="keywords" content={metaKeywords}/>
            </Helmet>
            
            <div className="maintenance-area d-flex justify-content-center align-items-center flex-column vh-100">
                <div className="maintenance-logo mb-4">
                    <img src={`${process.env.REACT_APP_IMAGE_URL}${maintenanceData?.c_picture_file}`} className="w-100" alt=""/>
                </div>

                <h4 className="maintenance-title text-center">{maintenanceData?.c_title}</h4>
                <span className="maintenance-text text-center">{maintenanceData?.c_sub_title}</span>

                <div className="maintenance-social">
                    {maintenanceData?.c_discord_url &&
                        <a href={maintenanceData?.c_discord_url} id="discord-link">
                            <img src={socialIcon1} alt=""/>
                        </a>
                    }
                    {maintenanceData?.c_twitter_url &&
                        <a href={maintenanceData?.c_twitter_url} id="twitter-link">
                            <img src={socialIcon2} alt=""/>
                        </a>
                    }
                    {maintenanceData?.c_opensea_url &&
                        <a href={maintenanceData?.c_opensea_url} id="opensea-link">
                            <img src={socialIcon3} alt=""/>
                        </a>
                    }
                    {maintenanceData?.c_instagram_url &&
                        <a href={maintenanceData?.c_instagram_url} id="instagram-link">
                            <img src={socialIcon4} alt=""/>
                        </a>
                    }
                </div>
            </div>
        </div>
    );
}

export default Maintenance;
