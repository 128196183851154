import React from "react";

const GameModeItem = ({ item }) => {
    return (
        <div className="game-mode-item d-flex flex-row justify-content-stretch align-items-stretch">

            <div className="game-mode-img ">
                <div className="image">
                    <img src={`${process.env.REACT_APP_IMAGE_URL}${item.data?.c_picture_file}`} alt="" />
                </div>
            </div>
            <div className="game-mode-content d-flex flex-column justify-content-center align-items-start">
                <h3 className="mb-0">{item.title}</h3>
                <div className="m-0 page-text text-white">
                    <div dangerouslySetInnerHTML={{__html: item.data?.c_description}}>
                        
                    </div>
                    <a href={item.data?.c_btn_url} className="btn-ylw">{item.data?.c_btn_name}</a>
                </div>
            </div>
        </div>
    );
};

export default GameModeItem;

