import React, { useEffect } from "react";

function PageHeader({ title }) {
  return (
    <div className="page-header">
      <h1 className="page-header-title">{title}</h1>
      <div className="page-header-line"></div>
    </div>
  );
}

export default PageHeader;
