import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';

const apiBaseUrl = process.env.REACT_APP_API_URL;

export const fetchSocial = createAsyncThunk('fetchSocial', async () => {
    const response = await axios.get(`${apiBaseUrl}/getByUrl?url=%2F%23social`);
    return response.data;
});

const socialSlice = createSlice({
    name: 'social',
    initialState: {
        socialData: {},
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSocial.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchSocial.fulfilled, (state, action) => {
                state.socialData = action.payload.data.node.data;
            })
            .addCase(fetchSocial.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default socialSlice.reducer;
