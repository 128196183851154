// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.search-results-container {
    width: 100%;
    max-width: 1400px;
}


.search-result-item {
    border-bottom: 1px solid rgba(112, 112, 112, .25);
    margin-bottom: 30px;
    padding-bottom: 30px;
    transition: .3s all;
}
.search-result-item:hover{
    transform: translateX(5px);
}

.search-result-item:last-child {
    margin-bottom: 0px;
}


.search-result-item a {
    font-family: 'Baloo Thambi 2', cursive;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: left;
    color: #202a7e;
    transition: .3s all;
}

.search-result-item a:hover {
    color: #ffca00;
}


@media(min-width:992px) {
    .search-result-item a {
        font-size: 24px;
        line-height: 1.33;
    }

    
}`, "",{"version":3,"sources":["webpack://./src/components/SearchResults/search-results.css"],"names":[],"mappings":";;AAEA;IACI,WAAW;IACX,iBAAiB;AACrB;;;AAGA;IACI,iDAAiD;IACjD,mBAAmB;IACnB,oBAAoB;IAGpB,mBAAmB;AACvB;AACA;IAGY,0BAA0B;AACtC;;AAEA;IACI,kBAAkB;AACtB;;;AAGA;IACI,sCAAsC;IACtC,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,kBAAkB;IAClB,iBAAiB;IACjB,sBAAsB;IACtB,gBAAgB;IAChB,cAAc;IAGd,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB;;;AAGA;IACI;QACI,eAAe;QACf,iBAAiB;IACrB;;;AAGJ","sourcesContent":["\n\n.search-results-container {\n    width: 100%;\n    max-width: 1400px;\n}\n\n\n.search-result-item {\n    border-bottom: 1px solid rgba(112, 112, 112, .25);\n    margin-bottom: 30px;\n    padding-bottom: 30px;\n    -webkit-transition: .3s all;\n    -o-transition: .3s all;\n    transition: .3s all;\n}\n.search-result-item:hover{\n    -webkit-transform: translateX(5px);\n        -ms-transform: translateX(5px);\n            transform: translateX(5px);\n}\n\n.search-result-item:last-child {\n    margin-bottom: 0px;\n}\n\n\n.search-result-item a {\n    font-family: 'Baloo Thambi 2', cursive;\n    font-size: 20px;\n    font-weight: 600;\n    font-stretch: normal;\n    font-style: normal;\n    line-height: 1.13;\n    letter-spacing: normal;\n    text-align: left;\n    color: #202a7e;\n    -webkit-transition: .3s all;\n    -o-transition: .3s all;\n    transition: .3s all;\n}\n\n.search-result-item a:hover {\n    color: #ffca00;\n}\n\n\n@media(min-width:992px) {\n    .search-result-item a {\n        font-size: 24px;\n        line-height: 1.33;\n    }\n\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
