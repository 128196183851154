import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from "axios";

const apiBaseUrl = process.env.REACT_APP_API_URL;

export const fetchGameModes = createAsyncThunk('fetchGameModePosts', async () => {
    const response = await axios.get(`${apiBaseUrl}/getByUrl?url=%2F%23game-modes`);
    return response.data;
});

export const gameModeSlice = createSlice({
    name:'gameModes',
    initialState:{
        gameModeData: {},
        gameModeList:[],
        loading: false,
        error: null,
    },

    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchGameModes.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchGameModes.fulfilled, (state, action) => {
                state.gameModeData = action.payload.data.node.data;
                state.gameModeList = action.payload.data.node.subNodes;
            })
            .addCase(fetchGameModes.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default gameModeSlice.reducer;