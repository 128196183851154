import React, { useState, useEffect } from "react";
import PageHeader from "../components/PageHeader";
import Accordion from "react-bootstrap/Accordion";
import { Footer } from "../components/Footer/Footer";
import {fetchFaq} from "../redux/Faq/faqSlice";

import "../components/Faq/faq.css";
import {useSelector, useDispatch} from "react-redux";

function Faq() {
  const faqData = useSelector((state)=> state.faq.faqData);
  const faqList = useSelector((state) => state.faq.faqList);
  const dispatch = useDispatch();

  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    dispatch(fetchFaq());
  },[dispatch]);

  return (
      <div className="d-flex flex-column justify-content-start align-items-center ">

        <div className="page-inner d-flex flex-column justify-content-start align-items-center">
          <PageHeader title={faqData?.c_title} />
          <div className="faq-container w-100">
            <Accordion className="w-100">
              {faqList.map((item, index) => {
                return (
                    <Accordion.Item
                        eventKey={index}
                        key={index}
                        onClick={() => handleAccordionToggle(index)}
                        className={activeIndex === index ? "active" : ""}
                    >
                      <Accordion.Header className="p-0">
                        {item.data?.c_title}
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="m-0">{item.data?.c_description}</p>
                      </Accordion.Body>
                    </Accordion.Item>
                );
              })}
            </Accordion>
          </div>
        </div>
      </div>
  );
}

export default Faq;
