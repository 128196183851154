import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from "axios";

const apiBaseUrl = process.env.REACT_APP_API_URL;

export const fetchFaq = createAsyncThunk('fetchFaqPosts', async () => {
    const response = await axios.get(`${apiBaseUrl}/getByUrl?url=%2F%23frequently-asked-questions`);
    return response.data;
});

export const faqSlice = createSlice({
    name: 'faq',
    initialState: {
        faqData: {},
        faqList: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchFaq.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchFaq.fulfilled, (state, action) => {
                state.faqData = action.payload.data.node.data;
                state.faqList = action.payload.data.node.subNodes;
            })
            .addCase(fetchFaq.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default faqSlice.reducer;
