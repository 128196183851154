import React, {useState, useEffect} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import SearchResults from "./pages/SearchResults";
import WhitePapers from "./pages/WhitePaper";
import NotFound from "./pages/NotFound";
import Contact from "./pages/Contact";
import "./fonts/fonts.css";
import "./index.css";
import SideButtons from "./components/SideButtons";
import loadingLogo from "./images/uknow-logo.png";
import loadingLogo2 from "./images/logo_bos.png";
import MarketPlaceModal from "./components/MarketPlaceModal";
import PlayNowModal from "./components/PlayNowModal";
import Home2 from "./pages/Home2";
import Maintenance from "./pages/Maintanance.jsx";
import Kvkk from "./pages/Kvkk.jsx";

import {useDispatch, useSelector} from "react-redux";
import {fetchHome} from "./redux/Home/homeSlice";

import {Helmet} from 'react-helmet';

const App = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [checkMaintenance, setCheckMaintenance] = useState(false);
    const [isMarketplaceModalOpen, setIsMarketplaceModalOpen] = useState(null);
    const [isPlayNowModalOpen, setIsPlayNowModalOpen] = useState(null);
    const [faviconUrl, setFaviconUrl] = useState('');
    const homeNode = useSelector((state) => state.home.homeData);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchHome());
    }, [dispatch]);

    useEffect(() => {
        setCheckMaintenance(true);
        setFaviconUrl(homeNode?.data?.c_fav_icon_file);
    }, [homeNode]);

    useEffect(() => {
        window.addEventListener("message", (event) => {
            if (event.data.what === "completed") {
                setIsLoading(false);
            }
        });
    }, []);

    useEffect(() => {
        if (window.location.pathname !== "/") {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        if (isMarketplaceModalOpen === null) {
            return;
        }

        if (isMarketplaceModalOpen) {
            if (window.fullpage_api) {
                window.fullpage_api.setAllowScrolling(false);
            }
        } else {
            if (window.fullpage_api) {
                window.fullpage_api.setAllowScrolling(true);
            }
        }
    }, [isMarketplaceModalOpen]);

    useEffect(() => {
        if (isPlayNowModalOpen === null) {
            return;
        }

        if (isPlayNowModalOpen) {
            if (window.fullpage_api) {
                window.fullpage_api.setAllowScrolling(false);
            }
        } else {
            if (window.fullpage_api) {
                window.fullpage_api.setAllowScrolling(true);
            }
        }
    }, [isPlayNowModalOpen]);

    if (!checkMaintenance) {
        return "";
    }

    return (
        <div>
              <Helmet>
                  <link id="favicon" rel="icon" type="image/png" href={process.env.REACT_APP_IMAGE_URL + faviconUrl}/>
              </Helmet>
            
            <BrowserRouter>
                {!(homeNode?.nodeId > 0) && <Maintenance/>}

                {homeNode?.nodeId > 0 && isMarketplaceModalOpen && (
                    <MarketPlaceModal
                        setIsMarketplaceModalOpen={setIsMarketplaceModalOpen}
                    />
                )}

                {homeNode?.nodeId > 0 && isPlayNowModalOpen && (
                    <PlayNowModal setIsPlayNowModalOpen={setIsPlayNowModalOpen}/>
                )}

                {homeNode?.nodeId > 0 && (
                    <SideButtons setIsPlayNowModalOpen={setIsPlayNowModalOpen}/>
                )}

                {homeNode?.nodeId > 0 && isLoading ? (
                    <div className="loading-screen">
                        <img src={loadingLogo} alt="" className="ls-1"/>
                        <img src={loadingLogo2} alt="" className="ls-2"/>
                    </div>
                ) : (
                    ""
                )}

                {homeNode?.nodeId > 0 && (
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <Home2 setIsMarketplaceModalOpen={setIsMarketplaceModalOpen}/>
                            }
                        />
                        <Route
                            path="/search-results"
                            element={
                                <SearchResults
                                    setIsMarketplaceModalOpen={setIsMarketplaceModalOpen}
                                />
                            }
                        />
                        <Route
                            path="/white-papers"
                            element={
                                <WhitePapers
                                    setIsMarketplaceModalOpen={setIsMarketplaceModalOpen}
                                />
                            }
                        />
                        <Route
                            path="/contact-us"
                            element={
                                <Contact setIsMarketplaceModalOpen={setIsMarketplaceModalOpen}/>
                            }
                        />
                        <Route
                            path="/kvkk"
                            element={
                                <Kvkk setIsMarketplaceModalOpen={setIsMarketplaceModalOpen}/>
                            }
                        />

                        <Route path="/maintenance" element={<Maintenance/>}/>
                        <Route
                            path="*"
                            element={
                                <NotFound setIsMarketplaceModalOpen={setIsMarketplaceModalOpen}/>
                            }
                        />
                    </Routes>
                )}
            </BrowserRouter>
        </div>
    );
};

export default App;
