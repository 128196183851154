import React, { useEffect, useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useSearchParams,useLocation } from "react-router-dom";
import { Squash as Hamburger } from "hamburger-react";

import "bootstrap/dist/css/bootstrap.min.css";
import { Collapse } from "react-bootstrap";
import "./header.css";

import navLogo from "../../images/uknow-logo.png";
import {useDispatch, useSelector} from "react-redux";
import { fetchSearch } from "../../redux/Search/searchSlice";
import { fetchHome } from "../../redux/Home/homeSlice";

function Header({ setIsMarketplaceModalOpen }) {
  const location = useLocation();
  
  const dispatch = useDispatch();
  const homePageData = useSelector((state) => state.home.homeData);
  
  const [searchTerm, setSearchTerm] = useState("");
  const [openSearch, setOpenSearch] = useState(false);
  const [isOpenHamburger, setIsOpenHamburger] = useState(false);
  let iframeThree = document.getElementById("iframeThree");
  const togglerRef = useRef(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const searchText = searchParams.get("searchText");

  useEffect(() => {
  }, [location]);

  useEffect(() => {
    dispatch(fetchHome());
  }, [dispatch]);
  
  useEffect(() => {
    if (searchText?.length > 0) {
      dispatch(fetchSearch(searchText));
    }
  }, [dispatch]);

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(fetchSearch(searchTerm));
    if (window.location.pathname !== "/search-results") {
      window.location.href = "/search-results?searchText=" + searchTerm;
    }
  };

  const handleLinkClick = () => {
    if (window.innerWidth <= 1200) {
      setIsOpenHamburger(false);
      togglerRef.current.click();
    }
  };
  
  return (
    <Navbar
      expand="xl"
      className={`${
        isOpenHamburger ? "opened " : ""
      }header p-0 d-flex justify-content-center`}
    >
      <Container fluid className="header-inner m-0">
        {!openSearch && (
          <Navbar.Brand href="/" className="p-0 m-0 d-none d-xl-flex">
            <img src={process.env.REACT_APP_IMAGE_URL + homePageData?.data?.c_logo_file} alt="" />
          </Navbar.Brand>
        )}
        <Navbar.Brand href="/" className="p-0 m-0 d-flex d-xl-none">
          <img src={process.env.REACT_APP_IMAGE_URL + homePageData?.data?.c_logo_file} alt="" />
        </Navbar.Brand>
        <Navbar.Toggle className="p-0 border-0" ref={togglerRef}>
          <Hamburger
            toggled={isOpenHamburger}
            toggle={setIsOpenHamburger}
            color={isOpenHamburger ? "#ffffff" : "#185bec"}
            rounded
            distance="md"
          />
        </Navbar.Toggle>
        <Navbar.Collapse>
          <div className="w-100 d-flex align-items-stretch">
            <Nav className="d-flex justify-content-center justify-content-xl-end  align-items-center w-100">
              {!openSearch && (
                <>
                  <button
                    disabled={
                      window.location.pathname === "/" &&
                      (window.location.hash == "" ||
                        window.location.hash.includes("about-us"))
                    }
                    onClick={() => {
                      handleLinkClick();
                      const hash = window.location.hash;
                      if (
                        window.location.pathname === "/" &&
                        (window.location.hash == "" ||
                          window.location.hash.includes("about-us"))
                      ) {
                        return;
                      } else {
                        window.location.href = "/#about-us";
                        iframeThree?.contentWindow?.postMessage({
                          from: "internalUrl",
                          to: "0",
                        });
                        iframeThree?.contentWindow?.postMessage({
                          from: "scroll",
                          to: "down",
                        });
                      }
                    }}
                    href="/#about-us"
                    className={`abt mb-3 mb-xl-0 nav-item nav-link p-0 ${location.hash === "#about-us" || location.hash === "" || location.hash === "#game-modes" ? "active" : ""}`}
                    >
                    ABOUT GAME
                  </button>

                  <a
                    href="/#this-is-smarty"
                    onClick={() => {
                      handleLinkClick();
                      iframeThree?.contentWindow?.postMessage({
                        from: "internalUrl",
                        to: "3",
                      });
                    }}
                    className={`mb-3 mb-xl-0 nav-item nav-link p-0 ${location.hash === "#this-is-smarty" || location.hash === "#nft-collection" ? "active" : ""}`}
                  >
                    NFTS
                  </a>
                  <a
                    href="/#roadmap"
                    onClick={() => {
                      handleLinkClick();
                      iframeThree?.contentWindow?.postMessage({
                        from: "internalUrl",
                        to: "3",
                      });
                    }}
                    className={` mb-3 mb-xl-0 nav-item nav-link p-0 ${location.hash === "#roadmap" ? "active" : ""}`}
                  >
                    ROADMAP
                  </a>
                  {/* <a
                    href="/#team"
                    onClick={() => {
                      handleLinkClick();
                      iframeThree?.contentWindow?.postMessage({
                        from: "internalUrl",
                        to: "3",
                      });
                    }}
                    className="mb-3 mb-xl-0 nav-item nav-link p-0"
                  >
                    TEAM
                  </a> */}
                  <a
                    href="/#social"
                    onClick={() => {
                      handleLinkClick();
                      iframeThree?.contentWindow?.postMessage({
                        from: "internalUrl",
                        to: "3",
                      });
                    }}
                    className={`mb-3 mb-xl-0 nav-item nav-link p-0 ${location.hash === "#social" ? "active" : ""}`}
                  >
                    SOCIAL
                  </a>
                  <a
                    href="/#frequently-asked-questions"
                    onClick={() => {
                      handleLinkClick();
                      iframeThree?.contentWindow?.postMessage({
                        from: "internalUrl",
                        to: "3",
                      });
                    }}
                    className={`mb-3 mb-xl-0 nav-item nav-link p-0 ${location.hash === "#frequently-asked-questions" ? "active" : "" }`}
                  >
                    FAQ
                  </a>
                  <div className="nav-buttons-container ms-xl-5 d-flex flex-column flex-xl-row justify-content-center align-items-center">
                    <div className="marketplace-btn-container w-100 mb-5 mb-xl-0">
                      <button
                        className="nav-item page-btn button-disabled"
                        onClick={() => {
                          handleLinkClick();
                          setIsMarketplaceModalOpen(true);
                        }}
                      >
                        MARKETPLACE
                      </button>

                      {/* <span>Coming Soon</span> */}
                    </div>
                    <a
                      href="/white-papers"
                      className="nav-item page-btn button-secondary mb-5 mb-xl-0"
                    >
                      WHITEPAPER
                    </a>
                  </div>
                </>
              )}
              <Form
                className="d-flex search-form h-100"
                onSubmit={handleSearch}
              >
                <div className="d-none d-xl-flex">
                  <Collapse in={openSearch} dimension="width">
                    <div id="search-collapse">
                      <Form.Control
                        type="search"
                        placeholder="Search"
                        className="header-search-input h-100"
                        aria-label="Search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                  </Collapse>

                  <Button
                    className={`${
                      openSearch ? "active" : ""
                    } nav-item btn-search-icon page-btn m-0`}
                    onClick={() => setOpenSearch(!openSearch)}
                    aria-controls="search-collapse"
                    aria-expanded={openSearch}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21.24"
                      height="22"
                      viewBox="0 0 21.24 22"
                    >
                      <path
                        d="m21.9 20.039-5.236-5.446a8.879 8.879 0 1 0-6.8 3.172 8.787 8.787 0 0 0 5.089-1.607l5.276 5.487a1.158 1.158 0 1 0 1.67-1.606zM9.866 2.317A6.565 6.565 0 1 1 3.3 8.882a6.573 6.573 0 0 1 6.566-6.565z"
                        transform="translate(-.984)"
                      />
                    </svg>
                  </Button>
                </div>
                <div className="d-flex d-xl-none w-100">
                  <div id="search-collapse" className="w-100">
                    <Form.Control
                      type="search"
                      placeholder="Search"
                      className="header-search-input w-100 h-100"
                      aria-label="Search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                  <Button
                    type="submit"
                    className={`active nav-item btn-search-icon page-btn m-0 w-auto`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21.24"
                      height="22"
                      viewBox="0 0 21.24 22"
                    >
                      <path
                        d="m21.9 20.039-5.236-5.446a8.879 8.879 0 1 0-6.8 3.172 8.787 8.787 0 0 0 5.089-1.607l5.276 5.487a1.158 1.158 0 1 0 1.67-1.606zM9.866 2.317A6.565 6.565 0 1 1 3.3 8.882a6.573 6.573 0 0 1 6.566-6.565z"
                        transform="translate(-.984)"
                      />
                    </svg>
                  </Button>
                </div>
              </Form>
            </Nav>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
