import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';

const apiBaseUrl = process.env.REACT_APP_API_URL;

export const fetchMaintenance = createAsyncThunk('fetchMaintenance', async () => {
    const response = await axios.get(`${apiBaseUrl}/getByUrl?url=%2Fmaintenance`);
    return response.data;
});

const maintenanceSlice = createSlice({
    name: 'maintenance',
    initialState: {
        maintenanceData: {},
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMaintenance.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchMaintenance.fulfilled, (state, action) => {
                state.maintenanceData = action.payload.data.node.data;
            })
            .addCase(fetchMaintenance.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default maintenanceSlice.reducer;
