import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from "axios";

const apiBaseUrl = process.env.REACT_APP_API_URL;

export const fetchNFTCollection = createAsyncThunk('fetchNFTPosts', async () => {
    const response = await axios.get(`${apiBaseUrl}/getByUrl?url=%2F%23nft-collection`);
    return response.data;
});

export const nftCollectionSlice = createSlice({
    name: 'nftCollections',
    initialState: {
        nftCollectionData: {},
        nftCollectionList: [],
        loading: false,
        error: null,
    },

    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchNFTCollection.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchNFTCollection.fulfilled, (state, action) => {
                state.nftCollectionData = action.payload.data.node.data;
                state.nftCollectionList = action.payload.data.node.subNodes;
            })
            .addCase(fetchNFTCollection.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default nftCollectionSlice.reducer;