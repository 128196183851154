import React, {useEffect, useState} from "react";
import PageHeader from "../components/PageHeader";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Header from "../components/Header/Header";
import {Footer} from "../components/Footer/Footer";
import {useSelector, useDispatch} from 'react-redux';
import {fetchWhitePaper} from "../redux/WhitePaper/WhitePaperSlice";
import {Link, useSearchParams} from "react-router-dom";
import {Helmet} from "react-helmet";

import WhitePaperItem from "../components/WhitePapers/WhitePaperItem";
import "../components/WhitePapers/white-papers.css";

import $ from 'jquery';

function WhitePapers({setIsMarketplaceModalOpen}) {

    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeywords, setMetaKeywords] = useState('');

    const stringToSlug = (str) => {
        return str
            .toLowerCase()
            .replace(/ /g, '-')
            .replace(/[^\w-]+/g, '');
    };

    const whitePaperData = useSelector((state) => state.whitePaper.whitePaperData);
    const whitePaperCategories = useSelector((state) => state.whitePaper.whitePaperCategories);
    const [searchParams, setSearchParams] = useSearchParams();
    const t = searchParams.get("t");
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchWhitePaper());
    }, [dispatch]);

    useEffect(() => {
        setMetaTitle(whitePaperData?.c_browser_title);
        setMetaDescription(whitePaperData?.c_meta_description);
        setMetaKeywords(whitePaperData?.c_meta_keywords);
    }, [whitePaperData]);

    useEffect(() => {

        if (t?.length > 0) {
            whitePaperCategories?.forEach((item, index) => {
                if (stringToSlug(item.title) === t) {
                    setSelectedItemIndex(index);
                    setIsParentSelected(true);
                    $(`a[data-node]`).removeClass("active");
                    $(`a[data-node="${t}"]`).addClass("active");
                }
            });

            whitePaperCategories?.flatMap(x => x.subNodes)?.forEach((item, index) => {
                if (stringToSlug(item.title) === t) {
                    setSelectedItemSubIndex(index);
                    setIsParentSelected(false);
                    $(`a[data-node]`).removeClass("active");
                    $(`a[data-node="${t}"]`).addClass("active");
                }
            });
        }
    }, [whitePaperCategories]);

    const [selectedItemIndex, setSelectedItemIndex] = useState(0);
    const [selectedItemSubIndex, setSelectedItemSubIndex] = useState(0);
    const [isParentSelected, setIsParentSelected] = useState(true);

    return (
        <div>
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
                <meta name="keywords" content={metaKeywords}/>
            </Helmet>
            
            <div className="page-container bg-white d-flex flex-column justify-content-center align-items-center">
                <Header setIsMarketplaceModalOpen={setIsMarketplaceModalOpen}/>
                <div className="page-inner d-flex flex-column justify-content-start align-items-center">
                    <div className="mt-5">
                        <PageHeader title={whitePaperData?.c_title}/>
                    </div>

                    <Tab.Container
                        defaultActiveKey="0"
                        onSelect={(index) => {
                            setSelectedItemIndex(parseInt(index));
                            setIsParentSelected(true);
                        }}
                    >
                        <Row className="w-100 p-0 m-0">
                            <Col sm={4} lg={3} className="p-0 ">
                                <Nav variant="pills" className="d-flex flex-column w-100">
                                    {whitePaperCategories?.map((item, index) => {
                                        return (
                                            <Nav.Item key={index} className="whitepaper-link">
                                                <Nav.Link data-node={`${stringToSlug(item.title)}`} eventKey={index}>{item.data?.c_title}</Nav.Link>
                                                <ul>
                                                    {item?.subNodes.map((subitem, subIndex) => {
                                                        return (
                                                            <li
                                                                key={subIndex}
                                                                onClick={() => {
                                                                    setSelectedItemSubIndex(subIndex);
                                                                    setIsParentSelected(false);
                                                                }}
                                                            >
                                                                <Nav.Link data-node={`${stringToSlug(subitem.title)}`} eventKey={`${index}-${subIndex}`}>
                                                                    {subitem.data?.c_title}
                                                                </Nav.Link>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </Nav.Item>
                                        );
                                    })}
                                </Nav>
                            </Col>
                            <Col sm={8} lg={9} className="p-0 ">
                                <Tab.Content className="whitepaper-items-container mt-5">
                                    {whitePaperCategories?.map((item, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={selectedItemIndex === index ? "" : "d-none"}
                                            >
                                                <WhitePaperItem
                                                    item={
                                                        isParentSelected ?
                                                            whitePaperCategories[selectedItemIndex] :
                                                            whitePaperCategories[selectedItemIndex].subNodes[
                                                                selectedItemSubIndex
                                                                ]
                                                    }
                                                    key={index}
                                                />
                                            </div>
                                        );
                                    })}
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
                <Footer/>
            </div>
        </div>
    );
}

export default WhitePapers;
