import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from "axios";

const apiBaseUrl = process.env.REACT_APP_API_URL;

export const fetchTeam = createAsyncThunk('fetchTeamPosts', async () => {
    const response = await axios.get(`${apiBaseUrl}/getByUrl?url=%2F%23team`);
    return response.data;
});

export const teamSlice = createSlice({
    name:'teams',
    initialState:{
        teamData: {},
        teamList:[],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTeam.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchTeam.fulfilled, (state, action) => {
                state.teamData = action.payload.data.node.data;
                state.teamList = action.payload.data.node.subNodes;
            })
            .addCase(fetchTeam.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default teamSlice.reducer;