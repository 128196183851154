// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/


.whitepaper-items-container {
    background-color: #fff;
}


.whitepaper-item h1{
    color: #202683;
    font-size: 30px;
}
.whitepaper-link {
    padding: 0;
    border: none;
    width: 100%;
    margin-bottom: 10px;
}

.whitepaper-link a {
    display: block;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    font-size: 12px;
    line-height: 1.51;
    letter-spacing: normal;
    text-align: left;
    color: #202683;
    border-radius: 0 !important;
    border-bottom: 1px solid #2027838d !important;
    transition: .2s all;
    padding: 0;
    padding-bottom: 10px;
}

.whitepaper-link a.active,
.whitepaper-link a:hover {
    color: #202683 !important;
    background-color: transparent !important;
    font-weight: bold;
}


@media(min-width:576px) {
    .whitepaper-link a {
        border-bottom:  none !important;
    }
}

@media(min-width:992px) {
    .whitepaper-link {
        margin: 13px 0;
    }

    .whitepaper-link a {
        padding: 0;
        font-size: 14px;

        line-height: 1.71;
    }

}`, "",{"version":3,"sources":["webpack://./src/components/WhitePapers/white-papers.css"],"names":[],"mappings":"AAAA;;;;;CAKC;;;AAGD;IACI,sBAAsB;AAC1B;;;AAGA;IACI,cAAc;IACd,eAAe;AACnB;AACA;IACI,UAAU;IACV,YAAY;IACZ,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,oCAAoC;IACpC,mBAAmB;IACnB,oBAAoB;IACpB,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,sBAAsB;IACtB,gBAAgB;IAChB,cAAc;IACd,2BAA2B;IAC3B,6CAA6C;IAG7C,mBAAmB;IACnB,UAAU;IACV,oBAAoB;AACxB;;AAEA;;IAEI,yBAAyB;IACzB,wCAAwC;IACxC,iBAAiB;AACrB;;;AAGA;IACI;QACI,+BAA+B;IACnC;AACJ;;AAEA;IACI;QACI,cAAc;IAClB;;IAEA;QACI,UAAU;QACV,eAAe;;QAEf,iBAAiB;IACrB;;AAEJ","sourcesContent":["/*\n* Prefixed by https://autoprefixer.github.io\n* PostCSS: v8.4.14,\n* Autoprefixer: v10.4.7\n* Browsers: last 4 version\n*/\n\n\n.whitepaper-items-container {\n    background-color: #fff;\n}\n\n\n.whitepaper-item h1{\n    color: #202683;\n    font-size: 30px;\n}\n.whitepaper-link {\n    padding: 0;\n    border: none;\n    width: 100%;\n    margin-bottom: 10px;\n}\n\n.whitepaper-link a {\n    display: block;\n    font-family: 'Open Sans', sans-serif;\n    font-weight: normal;\n    font-stretch: normal;\n    font-style: normal;\n    font-size: 12px;\n    line-height: 1.51;\n    letter-spacing: normal;\n    text-align: left;\n    color: #202683;\n    border-radius: 0 !important;\n    border-bottom: 1px solid #2027838d !important;\n    -webkit-transition: .2s all;\n    -o-transition: .2s all;\n    transition: .2s all;\n    padding: 0;\n    padding-bottom: 10px;\n}\n\n.whitepaper-link a.active,\n.whitepaper-link a:hover {\n    color: #202683 !important;\n    background-color: transparent !important;\n    font-weight: bold;\n}\n\n\n@media(min-width:576px) {\n    .whitepaper-link a {\n        border-bottom:  none !important;\n    }\n}\n\n@media(min-width:992px) {\n    .whitepaper-link {\n        margin: 13px 0;\n    }\n\n    .whitepaper-link a {\n        padding: 0;\n        font-size: 14px;\n\n        line-height: 1.71;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
