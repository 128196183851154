import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchModal} from "../redux/Modal/modalSlice";

function PlayNowModal({ setIsPlayNowModalOpen }) {
  const modals = useSelector((state) => state.modal.modalList);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchModal());
  }, [dispatch])

  const modal1 = modals?.find((modal) => modal.title === "Play Now" && modal.template?.type === "popup-item-1");
  const modal2 = modals?.find((modal) => modal.title === "Play Now" && modal.template?.type === "popup-item-2");

  if (modal1 !== undefined && modal1 !== null) {
    return (
        <div className="marketplace-modal">
          <div className="modal-content">
            <div className="modal-close" onClick={() => {
              setIsPlayNowModalOpen(false)
            }}>
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
              >
                <path
                    fill="currentColor"
                    d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59L7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12L5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
                />
              </svg>
            </div>
            <div className="modal-logo">
              <img src={`${process.env.REACT_APP_IMAGE_URL}${modal1?.data.c_picture_file}`} alt=""/>
            </div>

            <p className="modal-text">
              {modal1?.data.c_description}
            </p>
          </div>
        </div>
    )
  }
  else if (modal2 !== undefined && modal2 !== null) {
    return (
        <div className="play-now-modal">
          <div className="modal-content">
            <div
                className="modal-close"
                onClick={() => {
                  setIsPlayNowModalOpen(false);
                }}
            >
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
              >
                <path
                    fill="currentColor"
                    d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59L7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12L5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
                />
              </svg>
            </div>

            <h5 className="modal-header mb-4 text-center">{modal2?.data.c_title}</h5>
            <div className="modal-icons">
              <a href={modal2?.data.c_url_1}>
                <img src={`${process.env.REACT_APP_IMAGE_URL}${modal2?.data.c_picture_1_file}`} alt="" />
              </a>
              <a href={modal2?.data.c_url_2}>
                <img src={`${process.env.REACT_APP_IMAGE_URL}${modal2?.data.c_picture_2_file}`} alt="" />
              </a>
            </div>
            <p className="modal-text">
              {modal2?.data.c_description}
            </p>
          </div>
        </div>
    )
  }
}

export default PlayNowModal;
