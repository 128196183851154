import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from "axios";

const apiBaseUrl = process.env.REACT_APP_API_URL;

export const fetchRoadmap = createAsyncThunk('fetchRoadmap', async () => {
    const response = await axios.get(`${apiBaseUrl}/getByUrl?url=%2F%23roadmap`);
    return response.data;
});

export const roadmapSlice = createSlice({
    name:'roadmap',
    initialState:{
        data: {},
        items:[],
        loading: false,
        error: null,
    },

    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchRoadmap.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchRoadmap.fulfilled, (state, action) => {
                state.data = action.payload.data.node.data;
                const subNodes = action.payload.data.node.subNodes;
                const firstItem = subNodes[0];
                const lastItem = subNodes[subNodes.length - 1];
                
                state.items = [
                    firstItem,
                    ...subNodes,
                    lastItem,
                ];
            })
            .addCase(fetchRoadmap.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default roadmapSlice.reducer;