import React from "react";

const WhitePaperItem = ({ item }) => {
  return (
    <div className="whitepaper-item">
      <React.Fragment key={0}>
        <div dangerouslySetInnerHTML={{__html: item?.data?.c_content}}>

        </div>
      </React.Fragment>
    </div>
  );
};

export default WhitePaperItem;
