import React, {useState, useEffect} from "react";
import PageHeader from "../components/PageHeader";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import RoadmapItem from "../components/Roadmap/RoadmapItem";
import RoadmapWhite from "../images/roadmap-white.png";
import RoadmapBlue from "../images/roadmap-blue.webp";
import roadmapItemImage from "../images/roadmap-item-image.png";
import "../components/Roadmap/roadmap.css";
import {Swiper, SwiperSlide} from "swiper/react";
import "./swiper.css";
/* import "swiper/css/effect-fade";
 */
import {EffectFade, Mousewheel} from "swiper";

import {fetchRoadmap} from "../redux/Roadmap/roadmapSlice";
import {useSelector, useDispatch} from "react-redux";

function Roadmap2({setRoadmapSwiper}) {
    const [selectedItemIndex, setSelectedItemIndex] = useState(0);
    const [screenWidth, setScreenWidth] = useState(0);
    const [roadmapCount, setRoadmapCount] = useState(10);

    const roadmapData = useSelector((state) => state.roadmap.data);
    const roadmapList = useSelector((state) => state.roadmap.items);
    const dispatch = useDispatch();
    const [roadmapItems, setRoadmapItems] = useState([]);

    useEffect(() => {
        dispatch(fetchRoadmap());
    }, []);

    useEffect(() => {
        if (roadmapList.length > 0) {
            setRoadmapItems(
                roadmapList.map((item) => {
                    const descriptions = [];
                    item.data?.c_content.match(/"([^"]*)"/g).forEach((match) => {
                        descriptions.push(match.slice(1, -1));
                    });

                    return {
                        title: item.data?.c_title,
                        date: item.data?.c_sub_title,
                        image: `${process.env.REACT_APP_IMAGE_URL}${item.data?.c_picture_file}`,
                        description: descriptions, // ayrıştırılmış tırnak içi yazılar dizisi
                    };
                })
            );
        }
    }, [roadmapList]);
    
    useEffect(() => {
        function handleResize() {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const getLeftValue = (index) => {
        if (screenWidth < 1200) {
          return index * 70;
        }
        if (screenWidth < 1680 && screenWidth > 1200) {
          return index * 140;
        } else {
          return index * 150;
        }
      };
    
      const getLeftValueSec = (index) => {
        if (screenWidth < 1200) {
          if (selectedItemIndex >= roadmapCount - 5) {
            return (roadmapCount - 6) * 70;
          } else {
            return index * 70;
          }
        }
        if (screenWidth < 1680 && screenWidth > 1200) {
          if (selectedItemIndex >= roadmapCount - 5) {
    
            return (roadmapCount - 6) * 140;
          } else {
            return index * 140;
          }
        } else {
    
          if (selectedItemIndex >= roadmapCount - 5) {
            return (roadmapCount - 6) * 150;
          } else {
            return index * 150;
          }
        }
      };
    
      const getBottomValue = (index) => {
        if (screenWidth < 1200) {
          return "38px";
        }
        if (screenWidth < 1680 && screenWidth > 1200) {
          return "70px";
        } else {
          return "70px";
        }
      };

      if (roadmapItems.length > 0) {
        return (
          <div className="d-flex flex-column justify-content-center align-items-center h-100">
            <div className="roadmap-container page-inner d-flex flex-column justify-content-start align-items-center pt-5">
              <PageHeader title={roadmapData?.c_title} />
              <div className="d-flex justify-content-center align-items-center w-100 roadmap-inner">
                <Swiper
                  className="w-100 h-100 roadmapSwiper"
                  slidesPerView={1}
                  effect="fade"
                  mousewheel={true}
                  speed={500}
                  direction="vertical"
                  modules={[Mousewheel, EffectFade]}
                  onSlideChange={(item) => {
                    setSelectedItemIndex(item.activeIndex);
                    if (window.fullpage_api) {
                      window.fullpage_api.setAllowScrolling(false);
                      if (item.activeIndex === roadmapCount - 1) {
                        window.fullpage_api.moveSectionDown();
                      }
                      if (item.activeIndex === 0) {
                        window.fullpage_api.moveSectionUp();
                      }
                    }
                  }}
                  onSwiper={(swiper) => {
                    setRoadmapCount(swiper.slides.length);
                    setRoadmapSwiper(swiper);
                  }}
                >
                  {roadmapItems.map((item, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <Row className="roadmap-items-ctr w-100 p-0 m-0 d-flex justify-content-center align-items-center flex-wrap">
                          <Col
                            xs={12}
                            xl={4}
                            xxl={5}
                            className="p-0 pe-lg-4 d-flex justify-content-center align-items-center"
                          >
                            <RoadmapItem
                              index={index}
                              roadmapItems={roadmapItems}
                            />
                          </Col>
                          <Col
                            xs={12}
                            xl={8}
                            xxl={7}
                            className="p-0 roadmap-items items d-flex flex-row position-relative align-items-center"
                          >
                            {roadmapItems.map((item, index2) => {
                              const currentItemIndex =
                                selectedItemIndex + index2 - 2;
                              return (
                                <div
                                  key={index2}
                                  id={`roadmap-${index2 + 1}`}
                                  className={`${
                                    index2 === 0 ? "first-slide" : ""
                                  } ${
                                    index2 === roadmapItems.length - 1
                                      ? "last-slide"
                                      : ""
                                  } roadmap-item ${currentItemIndex+1 === selectedItemIndex ? 'active-elem' : ''}  ${
                                    (index > index2)  ? "updated" : ""
                                  } ${roadmapItems.length- 7 < selectedItemIndex ? 'last-five': ''} ${selectedItemIndex} ${
                                    ((selectedItemIndex + 5 <= index2)/*  && (roadmapItems.length-6 > selectedItemIndex) */) ? "updated" : ""
                                  } `}
                                  style={{
                                    position: "absolute",
                                    left: `${
                                      getLeftValue(index2) -
                                      getLeftValueSec(selectedItemIndex)
                                    }px`,
                                    bottom: `${
                                      index2 % 2 === 0 ? getBottomValue() : 0
                                    }`,
                                  }}
                                >
                                  {index >= index2 ? (
                                    <img src={RoadmapBlue} alt="" />
                                  ) : (
                                    <img src={RoadmapWhite} alt="" />
                                  )}
                                  {index >= index2 && (
                                    <div className="item-date">{item.date}</div>
                                  )}
                                </div>
                              );
                            })}
                          </Col>
                        </Row>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          </div>
        );
      }
    return (
      <div className="d-flex flex-column justify-content-center align-items-center h-100">
          
      </div>  
    );
}

export default Roadmap2;