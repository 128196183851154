import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import passionPunch from "../../images/passion-punch-footer.png";
import "./footer.css";

export const Footer = () => {
  return (
    <footer className="footer">
      <Row className="m-0 footer-inner">
        <Col
          className="footer-item p-0 pb-5 p-sm-5 p-xl-3 d-flex justify-content-center justify-content-xl-start align-items-center"
          xs={6}
          sm={6}
          xl={2}
        >
          <ul className=" m-0 p-0 d-flex flex-column justify-content-center align-items-center text-center">
            <li className="p-0 mb-2">
              <a href="/#game-modes" className="footer-link p-0">
                The Game
              </a>
            </li>
            <li className="p-0 mb-2">
              <a href="/#nft-collection" className="footer-link p-0">
                Nft
              </a>
            </li>
            <li className="p-0 mb-2">
              <a href="/#roadmap" className="footer-link p-0">
                Roadmap
              </a>
            </li>
            {/* <li className="p-0  ">
              <a href="/#team" className="footer-link p-0">
                Team
              </a>
            </li> */}
          </ul>
        </Col>
        <Col
          className="footer-item p-0 pb-5 p-sm-5 p-xl-3 d-flex justify-content-center align-items-center"
          xs={6}
          sm={6}
          xl={3}
        >
          <ul className="m-0 p-0 d-flex flex-column justify-content-center align-items-center text-center">
            <li className="p-0 mb-2">
              <a href="/#social" className="footer-link p-0">
                Social
              </a>
            </li>
            <li className="p-0 mb-2">
              <a href="/#frequently-asked-questions" className="footer-link p-0">
                FAQ
              </a>
            </li>
            <li className="p-0 mb-2">
              <a href="/contact-us" className="footer-link p-0">
                Contact Us
              </a>
            </li>
            <li className="p-0 ">
              <a href="/kvkk" className="footer-link p-0">
                Term of Use
              </a>
            </li>
          </ul>
        </Col>
        <Col
          className="footer-item p-0 pb-5 p-sm-5 p-xl-3 d-flex flex-column justify-content-center align-items-center"
          xs={12}
          sm={6}
          xl={4}
        >
          <a href="/white-papers" className="page-btn btn-white p-0 mb-4">
            WHITEPAPER
          </a>
          <a href="#" className="page-btn p-0">
            PLAY NOW
          </a>
        </Col>
        <Col
          className="footer-item  p-0 p-sm-5 p-xl-3 d-flex align-items-center flex-column justify-content-center align-items-xl-end"
          xs={12}
          sm={6}
          xl={3}
        >
          <div className="footer-copyright d-flex justify-content-center align-items-center flex-column ">
            <div className="footer-img mb-4">
              <a href="https://passionpunch.co/" target="_blank" rel="noreferrer">
              <img src={passionPunch} alt="" className="w-100" />
              </a>
              
            </div>
            <span className="d-inline-block copyright-text">
              All rights reserved @2023 uKnow
            </span>
          </div>
        </Col>
      </Row>
    </footer>
  );
};
