import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from "axios";

const apiBaseUrl = process.env.REACT_APP_API_URL;

export const fetchModal = createAsyncThunk('fetchModalPosts', async () => {
    const response = await axios.get(`${apiBaseUrl}/getByUrl?url=%2Fpopup-list`);
    return response.data;
});

export const modalSlice = createSlice({
    name:"modal",
    initialState:{
        modalList:[],
        loading: false,
        error: null,
    },
    reducer:{},
    extraReducers:(builder) => {
        builder
            .addCase(fetchModal.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchModal.fulfilled,(state,action) => {
                state.modalList = action.payload.data.node.subNodes;
            })
            .addCase(fetchModal.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
    
})

export default modalSlice.reducer;