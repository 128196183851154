import {configureStore} from '@reduxjs/toolkit';
import home from "./Home/homeSlice";
import aboutGame from "./AboutGame/aboutGameSlice";
import gameModes from "./GameMode/gameModeSlice";
import nftCollections from "./NFTCollection/nftCollectionSlice";
import roadmap from "./Roadmap/roadmapSlice";
import team from "./Team/TeamSlice";
import social from "./Social/socialSlice";
import faq from "./Faq/faqSlice";
import whitePaper from "./WhitePaper/WhitePaperSlice";
import search from "./Search/searchSlice";
import modal from "./Modal/modalSlice";
import maintenance from "./Maintenance/maintenanceSlice";
import kvkk from "./KVKK/kvkkSlice";


export default configureStore({
    reducer: {
        home: home,
        aboutGame: aboutGame,
        gameModes: gameModes,
        nftCollections: nftCollections,
        roadmap: roadmap,
        team: team,
        social: social,
        faq: faq,
        whitePaper: whitePaper,
        search: search,
        modal: modal,
        maintenance: maintenance,
        kvkk: kvkk,
    },
});

