import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const apiBaseUrl = process.env.REACT_APP_API_URL;

export const fetchSearch = createAsyncThunk('fetchSearchResults', async (searchTerm) => {
    const response = await axios.get(`${apiBaseUrl}/search?searchText=${searchTerm}`);
    return response.data;
});

const searchSlice = createSlice({
    name: 'search',
    initialState: {
        searchResults: {},
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSearch.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchSearch.fulfilled, (state, action) => {
                state.searchResults = action.payload.data.nodes;
                console.log("slice");
                console.log(action.payload.data.nodes);
            })
            .addCase(fetchSearch.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default searchSlice.reducer;
