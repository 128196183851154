import React, {useEffect} from "react";
import PageHeader from "../components/PageHeader";

import "../components/Contact/contact.css";
import { Row, Col } from "react-bootstrap";

import {useSelector, useDispatch} from 'react-redux';
import {fetchSocial} from '../redux/Social/socialSlice';

function Social() {
  const socialData = useSelector((state) => state.social.socialData);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSocial());
  },[dispatch]);

  return (
      <div className="d-flex flex-column justify-content-start align-items-center social-page" >
        <div className="page-inner d-flex flex-column justify-content-start align-items-center">
          <PageHeader title={socialData?.c_title} />
          <div className="d-flex justify-content-center align-items-center w-100">
            <Row className="w-100 contact-social-inner h-100 mx-0">
              <Col
                  sm={6}
                  className="contact-social-left d-flex justify-content-center align-items-center"
              >
                <div className="image">
                  <img src={`${process.env.REACT_APP_IMAGE_URL}${socialData?.c_picture_file}`} className="w-100" alt="" />
                </div>
              </Col>
              <Col
                  sm={6}
                  className="contact-social-right d-flex justify-content-center  text-center align-items-center  flex-column"
              >
              <span className="mb-4" dangerouslySetInnerHTML={{__html: socialData?.c_description}}>
              </span>
                <ul className="p-0 m-0 mt-4 d-flex flex-row justify-content-center  w-100">
                  {socialData.c_linkedin &&
                  <li className="p-0 m-0 px-3 social-item">
                    <a href={socialData?.c_linkedin}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path
                            fill="currentColor"
                            d="M6.94 5a2 2 0 1 1-4-.002a2 2 0 0 1 4 .002zM7 8.48H3V21h4V8.48zm6.32 0H9.34V21h3.94v-6.57c0-3.66 4.77-4 4.77 0V21H22v-7.93c0-6.17-7.06-5.94-8.72-2.91l.04-1.68z"
                        />
                      </svg>
                    </a>
                  </li>
                  }
                  {socialData.c_instagram &&
                  <li className="p-0 m-0 px-3 social-item">
                    <a href={socialData?.c_instagram}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path
                            fill="currentColor"
                            d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3Z"
                        />
                      </svg>
                    </a>
                  </li>
                  }
                  {socialData.c_discord &&
                  <li className="p-0 m-0 px-3 social-item">
                    <a href={socialData?.c_discord}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path
                            fill="currentColor"
                            d="M19.27 5.33C17.94 4.71 16.5 4.26 15 4a.09.09 0 0 0-.07.03c-.18.33-.39.76-.53 1.09a16.09 16.09 0 0 0-4.8 0c-.14-.34-.35-.76-.54-1.09c-.01-.02-.04-.03-.07-.03c-1.5.26-2.93.71-4.27 1.33c-.01 0-.02.01-.03.02c-2.72 4.07-3.47 8.03-3.1 11.95c0 .02.01.04.03.05c1.8 1.32 3.53 2.12 5.24 2.65c.03.01.06 0 .07-.02c.4-.55.76-1.13 1.07-1.74c.02-.04 0-.08-.04-.09c-.57-.22-1.11-.48-1.64-.78c-.04-.02-.04-.08-.01-.11c.11-.08.22-.17.33-.25c.02-.02.05-.02.07-.01c3.44 1.57 7.15 1.57 10.55 0c.02-.01.05-.01.07.01c.11.09.22.17.33.26c.04.03.04.09-.01.11c-.52.31-1.07.56-1.64.78c-.04.01-.05.06-.04.09c.32.61.68 1.19 1.07 1.74c.03.01.06.02.09.01c1.72-.53 3.45-1.33 5.25-2.65c.02-.01.03-.03.03-.05c.44-4.53-.73-8.46-3.1-11.95c-.01-.01-.02-.02-.04-.02zM8.52 14.91c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.84 2.12-1.89 2.12zm6.97 0c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.83 2.12-1.89 2.12z"
                        />
                      </svg>
                    </a>
                  </li>
                  }
                  {socialData.c_twitter &&
                  <li className="p-0 m-0 px-3 social-item">
                    <a href={socialData.c_twitter}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path
                            fill="currentColor"
                            d="M22.46 6c-.77.35-1.6.58-2.46.69c.88-.53 1.56-1.37 1.88-2.38c-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29c0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15c0 1.49.75 2.81 1.91 3.56c-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.22 4.22 0 0 1-1.93.07a4.28 4.28 0 0 0 4 2.98a8.521 8.521 0 0 1-5.33 1.84c-.34 0-.68-.02-1.02-.06C3.44 20.29 5.7 21 8.12 21C16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56c.84-.6 1.56-1.36 2.14-2.23Z"
                        />
                      </svg>
                    </a>
                  </li>
                  }
                </ul>
              </Col>
            </Row>
          </div>
        </div>
        {/*  <Footer /> */}
      </div>
  );
}

export default Social;
