import React from "react";
import { Footer } from "../components/Footer/Footer";
import Header from "../components/Header/Header";

import NotFoundImage from "../images/404.png";

function Faq({setIsMarketplaceModalOpen}) {
  return (
    <div className="page-container not-found-container bg-purple ">
      <Header setIsMarketplaceModalOpen={setIsMarketplaceModalOpen}/>
      <div className="page-inner d-flex flex-column justify-content-center align-items-center">
        <div className="not-found-inner w-100 d-flex justify-content-center align-items-center flex-column flex-lg-row justify-content-lg-around  align-items-center">
          <div className="image mb-5 mb-lg-0">
            <img src={NotFoundImage} alt="" />
          </div>
          <div className="content text-center">
            <h1 className="m-0">404</h1>
            <h2 className="m-0">Page Not Found</h2>
            <p>
              The page you are looking for might have been removed, had its name
              changed or is temporarily unavailable.
            </p>
          </div>
        </div>
      </div>
      <Footer />

    </div>
  );
}

export default Faq;
