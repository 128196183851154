import React from "react";

function RoadmapItem({ index, roadmapItems }) {
  const currentItem = { ...roadmapItems[index] };

  return (
    <div className="roadmap-detail-card">
      <div className="roadmap-detail-card-image">
        <img src={currentItem.image} alt="" />
      </div>
      <div className=" w-100 roadmap-detail-card-content d-flex flex-column justify-content-start align-items-start">
        <h4 className="rdc-title">{currentItem.title}</h4>
        <h3 className="rdc-date">{currentItem.date}</h3>
        <ul className="p-0">
          {currentItem.description.map((item, index) => {
            return <li key={index}>{item}</li>;
          })}
        </ul>
      </div>
    </div>
  );
}

export default RoadmapItem;
