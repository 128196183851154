import {useEffect} from "react";
import PageHeader from "../components/PageHeader";
import GameModeItem from "../components/GameModes/GameModeItem";

import {useSelector, useDispatch} from 'react-redux';
import { fetchGameModes } from '../redux/GameMode/gameModeSlice';

import "../components/GameModes/modes.css";

function GameModes() {
    const dispatch = useDispatch();
    const gameModesPageData = useSelector((state) => state.gameModes.gameModeData);
    const gameModeItems = useSelector((state) => state.gameModes.gameModeList);

    const gameModeError = useSelector((state) => state.gameModes.error);

    useEffect(()=>{
        dispatch(fetchGameModes());
    },[dispatch]);

    return (
    <div className="d-flex flex-column justify-content-center align-items-center h-100">
      <div className="page-inner d-flex flex-column justify-content-start align-items-center game-modes-content-ctr" >
          <PageHeader title={gameModesPageData?.c_title} />
        <div className="game-modes-container">
          {gameModeItems?.map((mode, index) => {
            return <GameModeItem key={index} item={mode} />;
          })}
        </div>
      </div>
      {/* 
      <Footer /> */}
    </div>
  );
}

export default GameModes;
