import React from "react";


const SearchResultItem = ({ result }) => {
  return (
    <div className="search-result-item ">
      <h3 className="mb-4">
        <a href={result.url}>{result.title}</a>
      </h3>
      <p className="mb-0 page-text">{result.data?.c_description}</p>
    </div>
  );
};

export default SearchResultItem;
