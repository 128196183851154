import React from "react";

import { Form, Row, Col } from "react-bootstrap";

function ContactForm() {
  return (
    <Form>
      <Row className="w-100 p-0 m-0 pe-md-5">
        <Col sm={6} md={12} lg={6} className="p-0 ">
          <Form.Group className="mb-4" controlId="formBasicText">
            <Form.Control type="text" placeholder="Name" />
          </Form.Group>

          <Form.Group className="mb-4 mb-lg-0
          " controlId="formBasicText">
            <Form.Control
              type="text"
              as="textarea"
              rows={3}
              className="textarea"
              placeholder="Message"
            />
          </Form.Group>
        </Col>
        <Col sm={6} md={12} lg={6} className="p-0 ps-sm-4 ps-md-0 ps-lg-4">
          <Form.Group className="mb-4" controlId="formBasicText">
            <Form.Control type="text" placeholder="Surname" />
          </Form.Group>

          <Form.Group className="mb-4" controlId="formBasicText">
            <Form.Control type="email" placeholder="Email" />
          </Form.Group>

          <button className="page-btn" type="submit">
            SEND
          </button>
        </Col>
      </Row>
    </Form>
  );
}

export default ContactForm;
