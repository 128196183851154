import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';

const apiBaseUrl = process.env.REACT_APP_API_URL;

export const fetchAboutGame = createAsyncThunk('fetchAboutGame', async () => {
    const response = await axios.get(`${apiBaseUrl}/getByUrl?url=%2F%23about-us`);
    return response.data;
});

const aboutGameSlice = createSlice({
    name: 'aboutGame',
    initialState: {
        aboutGameData: {},
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAboutGame.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAboutGame.fulfilled, (state, action) => {
                state.aboutGameData = action.payload.data.node.data;
            })
            .addCase(fetchAboutGame.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});
export default aboutGameSlice.reducer;

