import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from "axios";

const apiBaseUrl = process.env.REACT_APP_API_URL;

export const fetchWhitePaper = createAsyncThunk('fetchWhitePaperPosts', async () => {
    const response = await axios.get(`${apiBaseUrl}/getByUrl?url=%2Fwhite-papers`);
    return response.data;
});

export const whitePaperSlice = createSlice({
    name: 'whitePaper',
    initialState: {
        whitePaperData: {},
        whitePaperCategories: [],
        loading: false,
        error: null,
    },

    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchWhitePaper.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchWhitePaper.fulfilled, (state, action) => {
                state.whitePaperData = action.payload.data.node.data;
                state.whitePaperCategories = action.payload.data.node.subNodes;
            })
            .addCase(fetchWhitePaper.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default whitePaperSlice.reducer;