import React, { useState, useRef, useEffect } from "react";
import PageHeader from "../components/PageHeader";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "./splide.min.css";

import { useSelector, useDispatch } from "react-redux";
import { fetchNFTCollection } from "../redux/NFTCollection/nftCollectionSlice";


function NftCollection() {
  const splideRef = useRef(null);

  const handleSplideClick = (item, slideIndex) => {
    splideRef.current.go(slideIndex);
  };

  const nftCollectionPageData = useSelector(
    (state) => state.nftCollections.nftCollectionData
  );
  const nftCollection = useSelector(
    (state) => state.nftCollections.nftCollectionList
  );
  const dispatch = useDispatch();

  const nftCollectionError = useSelector((state) => state.nftCollections.error);

  useEffect(() => {
    dispatch(fetchNFTCollection());
  }, [dispatch]);
  

  return (
    <div className="d-flex flex-column justify-content-center align-items-center h-100 ">
      <div className="page-inner-secondary d-flex flex-column justify-content-start align-items-center ">
        <PageHeader title={nftCollectionPageData?.c_title} />
        <div className="nft-collection-container w-100 ">
          <Splide
            ref={splideRef}
            aria-label="The Nft Collection"
            onActive={(item) => {
              const videoContainerNotActive = document.getElementsByClassName(
                "nft-collection-item"
              );
              for (item of videoContainerNotActive) {
                const video = item.querySelector(".video");
                if(video){
                  video.pause();
                }
                
              }

              setTimeout(() => {
                if(document.querySelector(".nft-collection-item.is-active.is-visible .video")){
                  document.querySelector(".nft-collection-item.is-active.is-visible .video").play();
                }
              }, 150);
            }}
            options={{
              type: "loop",
              pagination: false,
              arrows: false,
              rewind: true,
              perScroll: 1,
              focus: "center",
              perPage: 4.5,
              gap: "50px",
              isNavigation: true,
              /* clones:0, */

              breakpoints: {
                576: {
                  perPage: 1.5,
                },
                768: {
                  perPage: 2.5,
                },
                1200: {
                  perPage: 3.5,
                },
              },
            }}
          >
            {nftCollection.map((item, index) => (
              <SplideSlide
                className={`nft-collection-item splide__slide__${index}`}
                key={index}
                /* onClick={(item) => {
                  handleSplideClick(item, index);
                }} */
              >
                <div
                  className="w-100 h-100"
                  dangerouslySetInnerHTML={{
                    __html: `
                    <video
                    src=${process.env.REACT_APP_IMAGE_URL}${item.data?.c_picture_file}
                    muted
                    playsinline="true"
                    class="video"
                    loop
                    />,
                  `,
                  }}
                ></div>
              </SplideSlide>
            ))}
          </Splide>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default NftCollection;
