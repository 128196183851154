import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';

const apiBaseUrl = process.env.REACT_APP_API_URL;

export const fetchHome = createAsyncThunk('fetchHome', async () => {
    const response = await axios.get(`${apiBaseUrl}/getByUrl?url=%2F`);
    return response.data;
});

const homeSlice = createSlice({
    name: 'home',
    initialState: {
        homeData: {},
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchHome.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchHome.fulfilled, (state, action) => {
                state.homeData = action.payload.data.node;
            })
            .addCase(fetchHome.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});
export default homeSlice.reducer;
