import React, {useState, useEffect} from "react";
import PageHeader from "../components/PageHeader";
import Header from "../components/Header/Header";
import {Footer} from "../components/Footer/Footer";
import {useDispatch, useSelector} from "react-redux";
import {fetchKvkk} from "../redux/KVKK/kvkkSlice";
import {Helmet} from "react-helmet";

function Kvkk({setIsMarketplaceModalOpen}) {
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeywords, setMetaKeywords] = useState('');
    
    const kvkk = useSelector((state) => state.kvkk.data);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchKvkk())
    }, [dispatch]);

    useEffect(() => {
        setMetaTitle(kvkk?.c_browser_title);
        setMetaDescription(kvkk?.c_meta_description);
        setMetaKeywords(kvkk?.c_meta_keywords);
    }, [kvkk]);

    return (
        <div>
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription}/>
                <meta name="keywords" content={metaKeywords}/>
            </Helmet>
            
            <div className="page-container bg-white d-flex justify-content-center align-items-center flex-column">
                <Header setIsMarketplaceModalOpen={setIsMarketplaceModalOpen}/>
                <div className="page-inner d-flex flex-column justify-content-center align-items-center" style={{minHeight: '90vh'}}>
                    <PageHeader title={kvkk?.c_title}/>
                    <div dangerouslySetInnerHTML={{__html: kvkk?.c_content}}>

                    </div>
                </div>
                <Footer/>
            </div>
        </div>
    );
}

export default Kvkk;
