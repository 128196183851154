import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';

const apiBaseUrl = process.env.REACT_APP_API_URL;

export const fetchKvkk = createAsyncThunk('fetchKvkk', async () => {
    const response = await axios.get(`${apiBaseUrl}/getByUrl?url=%2Fkvkk`);
    console.log("ekel");
    return response.data;
});

const kvkkSlice = createSlice({
    name: 'kvkk',
    initialState: {
        data: {},
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchKvkk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchKvkk.fulfilled, (state, action) => {
                state.data = action.payload.data.node.data;
            })
            .addCase(fetchKvkk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default kvkkSlice.reducer;
