import React, { useState } from "react";
import PageHeader from "../components/PageHeader";
import "../components/Smarty/smarty2.css";
import smartyFirst from "../images/default_character_replaced_for_gif2.png";
import stepOneSmarty from "../images/step01.png";
import stepSecondSmarty from "../images/step02.png";
import stepThirdSmarty from "../images/step03.png";
import animBg from "../images/anim-bg.png";
import nftSingularBged from "../images/nft-singular-bged.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import "./swiper.css";
import "swiper/css/effect-fade";
import { EffectFade, Mousewheel } from "swiper";

import smarty1 from "../images/smarty-1-res.png";
import smarty2 from "../images/smarty-2-res.png";
import smarty3 from "../images/smarty-3-res.png";
import smarty4 from "../images/smarty-4-res.png";
import smarty5 from "../images/smarty-5-res.png";

function SmartySection({ section, setSmartySwiper }) {
  const [modes, setModes] = useState([
    {
      id: 1,
      name: "Smarty 1",
      img: smarty1,
      type: "img",
      attributes: {},
    },
    {
      id: 2,
      name: "Smarty 1",
      img: smarty1,
      type: "img",
      attributes: {},
    },
    {
      id: 3,
      name: "Smarty 2",
      img: smarty2,
      type: "img",
      attributes: {
        hair: "Hair",
        footwear: "Footwear",
        skincolor: "Skin Color",
      },
    },
    {
      id: 4,
      name: "Smarty 3",
      img: smarty3,
      type: "img",
      attributes: {
        hat: "Hat",
        moustache: "Moustache",
        bottomwear: "Bottomwear",
      },
    },
    {
      id: 5,
      name: "Smarty 4",
      img: smarty4,
      type: "img",
      attributes: {
        glasses: "Glasses",
        clothing: "Clothing",
        hoverboard: "Hoverboard",
      },
    },
    {
      id: 6,
      name: "Smarty 5",
      img: smarty5,
      type: "img",
      attributes: {
        img: animBg,
        background: "Background",
      },
    },
    {
      id: 7,
      name: "Smarty 5",
      img: smarty5,
      type: "img",
      attributes: {
        img: animBg,
        background: "Background",
      },
    },
  ]);

  return (
    <div className="d-flex flex-column justify-content-center align-items-center smarty-section-inner h-100 nft-singular">
      <div className="page-inner d-flex flex-column justify-content-start align-items-center">
        <PageHeader title="This is Smarty" />
        <div
          className="d-flex justify-content-center align-items-center w-100"
          style={{ height: "500px" }}
        >
          <Swiper
            className="w-100  h-100 smartyContainer "
            slidesPerView={1}
            effect="fade"
            mousewheel={true}
            speed={1000}
            direction="vertical"
            modules={[Mousewheel, EffectFade]}
            onSlideChange={(item) => {
              if (window.fullpage_api) {
                window.fullpage_api.setAllowScrolling(false);
                if (item.activeIndex === 6) {
                  window.fullpage_api.moveSectionDown();
                }
                if (item.activeIndex === 0) {
                  window.fullpage_api.moveSectionUp();
                }
              }

              const allSlides = document.querySelectorAll(
                ".smarty-section-inner .smarty-attribute-item"
              );
              allSlides.forEach((slide) =>
                slide.classList.remove("fadeInDown1")
              );
              allSlides.forEach((slide) =>
                slide.classList.remove("fadeInDown2")
              );
              allSlides.forEach((slide) =>
                slide.classList.remove("fadeInDown3")
              );
              allSlides.forEach((slide) => slide.classList.remove("fadeLeft1"));
              allSlides.forEach((slide) =>
                slide.classList.remove("fadeRight1")
              );
              allSlides.forEach((slide) => slide.classList.remove("fadeLeft2"));
              allSlides.forEach((slide) =>
                slide.classList.remove("fadeRight2")
              );
              allSlides.forEach((slide) => slide.classList.remove("fadeLeft3"));
              allSlides.forEach((slide) =>
                slide.classList.remove("fadeRight3")
              );
              setTimeout(() => {
                const currentSlide = document.querySelectorAll(
                  ".smarty-section-inner .swiper-slide.swiper-slide-active .smarty-attribute-item"
                );

                currentSlide.forEach((slide, index) => {
                  /* const className = `fadeInDown${((index % 3) + 1)}`;
                  slide.classList.add(className);
                  console.log(slide) */

                  if (slide.classList.contains("right")) {
                    slide.classList.add(`fadeLeft${(index % 3) + 1}`);
                  } else {
                    slide.classList.add(`fadeRight${(index % 3) + 1}`);
                  }
                });
              }, 200);
            }}
            onSwiper={(swiper) => {
              setSmartySwiper(swiper);
            }}
          >
            {modes.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <div
                    className={`${
                      modes[index].attributes?.background ? "bg-img " : " "
                    } ${
                      modes[index].type === "gif" ? "gif " : "img "
                    } smarty-section-container`}
                  >
                    <img className="smarty-img" src={modes[index].img} alt="" />
                    <div className="smarty-attributes">
                      {modes[index].attributes?.background ? (
                        <div
                          className="right smarty-attribute-item "
                          id="background"
                        >
                          <div className="line">
                            <div className="circle"></div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <span className="attr-header">Attribute</span>
                            <span className="attr-title">
                              {modes[index].attributes.background
                                ? modes[index].attributes.background
                                : ""}
                            </span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {modes[index].attributes?.hair ? (
                        <div className="right smarty-attribute-item " id="hair">
                          <div className="line">
                            <div className="circle"></div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <span className="attr-header">Attribute</span>
                            <span className="attr-title">
                              {modes[index].attributes.hair}
                            </span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {modes[index].attributes?.hat ? (
                        <div className="right smarty-attribute-item " id="hat">
                          <div className="line">
                            <div className="circle"></div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <span className="attr-header">Attribute</span>
                            <span className="attr-title">
                              {modes[index].attributes.hat}
                            </span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {modes[index].attributes?.glasses ? (
                        <div
                          className="right smarty-attribute-item "
                          id="glasses"
                        >
                          <div className="line">
                            <div className="circle"></div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <span className="attr-header">Attribute</span>
                            <span className="attr-title">
                              {modes[index].attributes.glasses}
                            </span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {modes[index].attributes?.moustache ? (
                        <div
                          className="right smarty-attribute-item "
                          id="moustache"
                        >
                          <div className="line">
                            <div className="circle"></div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <span className="attr-header">Attribute</span>
                            <span className="attr-title">
                              {modes[index].attributes.moustache}
                            </span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {modes[index].attributes?.skincolor ? (
                        <div
                          className="right smarty-attribute-item "
                          id="skin-color"
                        >
                          <div className="line">
                            <div className="circle"></div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <span className="attr-header">Attribute</span>
                            <span className="attr-title">
                              {modes[index].attributes.skincolor}
                            </span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {modes[index].attributes?.clothing ? (
                        <div
                          className="left smarty-attribute-item "
                          id="clothing"
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <span className="attr-header">Attribute</span>
                            <span className="attr-title">
                              {modes[index].attributes.clothing}
                            </span>
                          </div>
                          <div className="line">
                            <div className="circle"></div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {modes[index].attributes?.footwear ? (
                        <div
                          className="left smarty-attribute-item "
                          id="footwear"
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <span className="attr-header">Attribute</span>
                            <span className="attr-title">
                              {modes[index].attributes.footwear}
                            </span>
                          </div>
                          <div className="line">
                            <div className="circle"></div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {modes[index].attributes?.bottomwear ? (
                        <div
                          className="left smarty-attribute-item "
                          id="bottomwear"
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <span className="attr-header">Attribute</span>
                            <span className="attr-title">
                              {modes[index].attributes.bottomwear}
                            </span>
                          </div>
                          <div className="line">
                            <div className="circle"></div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {modes[index].attributes?.hoverboard ? (
                        <div
                          className="right smarty-attribute-item "
                          id="hoverboard"
                        >
                          <div className="line">
                            <div className="circle"></div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <span className="attr-header">Attribute</span>
                            <span className="attr-title">
                              {modes[index].attributes.hoverboard}
                            </span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default SmartySection;
