import React from "react";
import PageHeader from "../components/PageHeader";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import ContactSocial from "../components/Contact/ContactSocial";
import ContactForm from "../components/Contact/ContactForm";
import Header from "../components/Header/Header";
import { Footer } from "../components/Footer/Footer";


import "../components/Contact/contact.css";

function Contact({setIsMarketplaceModalOpen}) {
  return (
    <div className="page-container bg-white d-flex flex-column justify-content-center align-items-center ">
      <Header setIsMarketplaceModalOpen={setIsMarketplaceModalOpen}/>
      <div className="contact-container w-100">
        <div className="page-inner d-flex flex-column justify-content-center align-items-center" style={{minHeight:'90vh'}}>
          <PageHeader title="CONTACT US" />
          <Row className="w-100 p-0 m-0">
            <Col xs={12} md={6} className="p-0 contact-form">
              <ContactForm />
            </Col>
            <Col xs={12} md={6} className="p-0 contact-social">
              <ContactSocial />
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
